<script lang="ts">
    import type { Data } from '@/interfaces/types';
    import PostBox from './PostBox.svelte';
    import PostDeleteDialog from './PostDeleteDialog.svelte';

    export let data;
    export let authUser: {};
    export let paymentSettings;
    export let reportStatuses: any;
    export let PostsPaginator: any;
    export let loggedUserAvailableAmount: number;
    export let minPostDeletionLimit: number;
    export let creatorCanEarnMoney: boolean;

    function handlePostAction(event: CustomEvent<{ action: string; postId: number }>) {
        const { action, postId } = event.detail;
    }
</script>

{#if data?.length}
    <div>
        {#each data as postData}
            <PostBox
                data="{postData}"
                {authUser}
                {reportStatuses}
                {PostsPaginator}
                {loggedUserAvailableAmount}
                {minPostDeletionLimit}
                {creatorCanEarnMoney}
                {paymentSettings}
                on:postAction="{handlePostAction}"
            />
            <hr />
        {/each}

        <PostDeleteDialog />
    </div>
{:else}
    <div class="d-flex justify-content-center align-items-center">
        <div class="col-10">
            <img src="/img/no-content-available.svg" alt="No content available" />
        </div>
    </div>
    <div class="d-flex justify-content-center align-items-center">
        <h5 class="mb-2 mt-2 text-center">No posts available</h5>
    </div>
{/if}
